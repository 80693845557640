<template>
  <v-container class="align-self-start">
    <ValidationObserver ref="obs" v-slot="{ invalid, validated, passes }"
      class="align-self-start col-12 pa-0">
      <v-form @submit.prevent="passes(save)">
        <v-container>
          <v-row
            align="start"
            justify="start"
          >
            <v-col cols="12" md="3" class="pt-0">
              <v-menu
                v-model="menuFecha"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="computedFechaFormatted"
                    filled
                    label="Desde"
                    prepend-icon="mdi-calendar"
                    hide-details
                    readonly
                    v-on="on"
                    disabled
                  ></v-text-field>
                </template>
                <v-date-picker v-model="fecha" @input="menuFecha = false"></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="9" class="pt-0">
              <ValidationProvider name="Cliente" rules="required" v-slot="{ errors, valid }">
                <v-autocomplete
                  autofocus
                  filled
                  v-model="cliente"
                  hide-details
                  :loading="loadingClientes"
                  :items="clientes"
                  :search-input.sync="clientesQuery"
                  cache-items
                  clearable
                  required
                  label="Cliente"
                  class="mb-2"
                  placeholder="Buscar cliente"
                  item-text="RazonSocial"
                  return-object
                  :error-messages="errors"
                  :success="valid"
                  @input="setCliente"
                  :no-data-text="noDataText"
                ></v-autocomplete>
              </ValidationProvider>

              <a v-show="(cliente !== null && saldoCte > 0)" class="ml-3 saldo-adeudado" title="Haga click para ir a la cuenta corriente del cliente" @click="openCtaCte(cliente)">Saldo adeudado: {{ numberFormat(saldoCte, moneyFormatOptions) }}</a>
            </v-col>
          </v-row>

          <ValidationProvider name="Items" rules="required" v-slot="{ errors, valid }">
            <v-data-table
              v-model="items"
              :headers="$vuetify.breakpoint.xs ? headersXs : headers"
              :items="items"
              class="elevation-1 align-self-start col-12 pa-0 mb-4"
              item-key="id"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:top>
                <v-toolbar flat color="white">
                  <v-toolbar-title>Items</v-toolbar-title>

                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  ></v-divider>

                  <v-spacer></v-spacer>

                  <v-btn :disabled="cliente === null" small color="primary" @click="openNewItem()">Nuevo</v-btn>
                </v-toolbar>
              </template>
              <template v-slot:item.medioDePago="{ item }">
                {{ item.medioDePago.detalle }}
              </template>
              <template v-slot:item.banco="{ item }">
                <span v-if="item.banco">{{ item.banco.detalle }}</span>
              </template>
              <template v-slot:item.nroCheque="{ item }">
                <span v-show="item.nroCheque > 0">{{ item.nroCheque }}</span>
              </template>
              <template v-slot:item.importe="{ item }">
                <span class="text-no-wrap">
                  {{ numberFormat(item.importe, moneyFormatOptions) }}
                </span>
              </template>
              <template v-slot:item.fechaVto="{ item }">
                <span class="text-no-wrap">
                  {{ formatDate(item.fechaVto) }}
                </span>
              </template>
              <template v-slot:item.fechaEmision="{ item }">
                <span class="text-no-wrap">
                  {{ formatDate(item.fechaEmision) }}
                </span>
              </template>
              <template v-slot:item.action="{ item }">
                <span class="text-no-wrap">
                  <v-icon
                    color="primary"
                    class="ml-2"
                    @click="openEditItem(item)"
                    aria-label="Editar"
                    title="Editar"
                  >
                    mdi-pencil
                  </v-icon>

                  <v-icon
                    color="error"
                    class="ml-2"
                    @click="removeItem(item)"
                    aria-label="Quitar"
                    title="Quitar"
                  >
                    mdi-delete
                  </v-icon>
                </span>
              </template>
            </v-data-table>

            <template v-if="!valid">
              <template v-for="(error, key) in errors">
                <div :key="key" class="caption error--text my-2">{{ error }}</div>
              </template>
            </template>
          </ValidationProvider>

          <ValidationProvider
            v-if="$vuetify.breakpoint.smAndUp"
            name="Comentario"
            rules=""
            v-slot="{ errors, valid }"
          >
            <v-textarea
              v-model="datosTapa.comentario"
              label="Comentario"
              :error-messages="errors"
              :success="valid"
            ></v-textarea>
          </ValidationProvider>

          <v-row
            class="mt-4"
            :class="{ 'text-right': $vuetify.breakpoint.smAndUp }"
            :justify="$vuetify.breakpoint.smAndUp ? 'end' : 'space-between'"
          >
            <v-col cols="auto" :class="{ 'text-right': $vuetify.breakpoint.xs }" align-self="center">
              <span class="caption">{{ $vuetify.breakpoint.smAndUp ? 'Importe Total' : 'Total' }}</span>: <span class="text-no-wrap green--text font-weight-bold">{{ numberFormat(datosTapa.importeTotal, moneyFormatOptions) }}</span>
            </v-col>

            <v-col cols="auto" v-if="$vuetify.breakpoint.smAndUp">
                <v-btn
                  type="submit"
                  color="primary"
                  :block="$vuetify.breakpoint.xsOnly"
                  dark
                  :loading="saving"
                  @click.prevent="passes(save)"
                >
                  Guardar
                </v-btn>

                <v-btn
                  class="ml-4"
                  color="primary"
                  :block="$vuetify.breakpoint.xsOnly"
                  dark
                  :loading="saving"
                  @click.prevent="passes(saveAndShare)"
                >
                  Guardar y Compartir
                </v-btn>
            </v-col>
          </v-row>

          <div v-if="$vuetify.breakpoint.xsOnly" class="mt-4">
            <v-btn
              color="secondary"
              block
              dark
              @click="addComment"
            >
              Agregar comentario
            </v-btn>
          </div>

          <div v-if="$vuetify.breakpoint.xsOnly" class="mt-4 text-right">
              <v-btn
                type="submit"
                color="primary"
                :block="$vuetify.breakpoint.xsOnly"
                dark
                :loading="saving"
                @click.prevent="passes(save)"
              >
                Guardar
              </v-btn>

              <v-btn
                class="mt-4"
                color="primary"
                :block="$vuetify.breakpoint.xsOnly"
                dark
                :loading="saving"
                @click.prevent="passes(saveAndShare)"
              >
                Guardar y Compartir
              </v-btn>
          </div>
        </v-container>
      </v-form>
    </ValidationObserver>

    <v-dialog v-model="itemFormDialog" max-width="500px">
      <ValidationObserver ref="item" v-slot="{ passes }">
        <v-form @submit.prevent="passes(saveItem)">
          <v-card>
            <v-card-title>
              <span class="headline">
                {{ formTitle }}
              </span>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text>
              <ValidationProvider
                name="Medio de Pago"
                rules="required"
                v-slot="{ errors, valid }"
                class="mb-4"
              >
                <v-select
                  ref="mediosDePago"
                  v-model="item.medioDePago"
                  :items="mediosDePago"
                  item-text="detalle"
                  item-value="id"
                  return-object
                  :error-messages="errors"
                  :success="valid"
                  required
                  label="Medio de Pago"
                ></v-select>
              </ValidationProvider>

              <ValidationProvider
                name="Importe"
                rules="required|min_value:0.01"
                v-slot="{ errors, valid }"
                class="mb-4"
              >
                <v-text-field
                  type="number"
                  step="0.01"
                  min="0.01"
                  v-model.number="item.importe"
                  label="Importe"
                  :error-messages="errors"
                  :success="valid"
                  required
                ></v-text-field>
              </ValidationProvider>

              <div v-if="chequeFieldsAreVisible">
                <ValidationProvider
                  name="Banco"
                  rules="required"
                  v-slot="{ errors, valid }"
                  class="mb-4"
                >
                  <v-select
                    ref="bancos"
                    v-model="item.banco"
                    :items="bancos"
                    item-text="detalle"
                    item-value="id"
                    return-object
                    :error-messages="errors"
                    :success="valid"
                    required
                    label="Banco"
                  ></v-select>
                </ValidationProvider>

                <ValidationProvider
                  name="Número"
                  rules="required|min_value:1|max_value:99999999"
                  v-slot="{ errors, valid }"
                  class="mb-4"
                >
                  <v-text-field
                    type="number"
                    step="1"
                    min="1"
                    max="99999999"
                    v-model.number="item.nroCheque"
                    label="Número"
                    :error-messages="errors"
                    :success="valid"
                    required
                  ></v-text-field>
                </ValidationProvider>

                <v-menu
                  v-model="menuFechaEmision"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <ValidationProvider
                      name="Fecha de Emisión"
                      rules="required"
                      v-slot="{ errors, valid }"
                      class="mb-4"
                    >
                      <v-text-field
                        v-model="computedFechaEmisionFormatted"
                        label="Fecha de Emisión"
                        prepend-icon="mdi-calendar"
                        clearable
                        readonly
                        v-on="on"
                        :error-messages="errors"
                        :success="valid"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </template>
                  <v-date-picker v-model="item.fechaEmision" @change="setFechaVtoMin" @input="menuFechaEmision = false"></v-date-picker>
                </v-menu>

                <v-menu
                  v-model="menuFechaVto"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <ValidationProvider
                      name="Fecha Vencimiento"
                      rules="required"
                      v-slot="{ errors, valid }"
                      class="mb-4"
                    >
                      <v-text-field
                        v-model="computedFechaVtoFormatted"
                        label="Fecha Vencimiento"
                        prepend-icon="mdi-calendar"
                        clearable
                        readonly
                        v-on="on"
                        :error-messages="errors"
                        :success="valid"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </template>
                  <v-date-picker v-model="item.fechaVto" :min="fechaVtoMin" @input="menuFechaVto = false"></v-date-picker>
                </v-menu>

                <ValidationProvider
                  name="Librador"
                  rules="required"
                  v-slot="{ errors, valid }"
                  class="mb-4"
                >
                  <v-text-field
                    v-model="item.librador"
                    label="Librador"
                    :error-messages="errors"
                    :success="valid"
                    required
                    clearable
                  ></v-text-field>
                </ValidationProvider>
              </div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                type="submit"
                color="primary"
                text
              >
                Guardar
              </v-btn>

              <v-btn text @click="closeItemForm">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </ValidationObserver>
    </v-dialog>

    <v-dialog v-model="commentDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">
            Agregar Comentario
          </span>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-textarea
            v-model="datosTapa.comentario"
            label="Comentario"
          ></v-textarea>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="closeCommentDialog">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogCtaCte" scrollable fullscreen>
      <CtaCteList
        :active="dialogCtaCte"
        :cliente="clienteCtaCte"
        @dismiss="closeCtaCte"
      />
    </v-dialog>

    <v-dialog v-model="dialogChangeClient" scrollable persistent>
      <v-card>
        <v-toolbar color="warning" dark flat>
          AVISO
        </v-toolbar>

        <v-card-text class="text-h6 pa-12">
          ¿Desea eliminar los ítems cargados?.
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn
            color="primary"
            text
            @click="changeClientWhithItems(true)"
          >
            Si
          </v-btn>

          <v-btn
            color="primary"
            text
            @click="changeClientWhithItems(false)"
          >
            No
          </v-btn>

          <v-btn text @click="cancelChangeClientDialog()">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="sharePDFDialog" max-width="500px">
      <ValidationObserver ref="item" v-slot="{ passes }">
        <v-form @submit.prevent="passes(sharePDF)">
          <v-card>
            <v-card-title>
              <span class="headline">
                Compartir PDF
              </span>
            </v-card-title>

            <v-divider></v-divider>

            <v-tabs
              v-model="tab"
              icons-and-text
            >
              <v-tabs-slider></v-tabs-slider>

              <v-tab href="#tab-pdf">
                PDF
                <v-icon>mdi-file-pdf-box</v-icon>
              </v-tab>

              <v-tab href="#tab-whatsapp">
                WhatsApp
                <v-icon>mdi-whatsapp</v-icon>
              </v-tab>

              <v-tab href="#tab-email">
                Email
                <v-icon>mdi-email</v-icon>
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item
                value="tab-pdf"
              ></v-tab-item>

              <v-tab-item
                value="tab-whatsapp"
              >
                <v-card flat>
                  <v-card-text>
                    <ValidationProvider
                      name="Celular"
                      rules="required"
                      v-slot="{ errors, valid }"
                      class="mb-4"
                    >
                      <v-text-field
                        v-model="clienteWhatsApp"
                        label="Celular"
                        :error-messages="errors"
                        :success="valid"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item
                value="tab-email"
              >
                <v-card flat>
                  <v-card-text>
                    <ValidationProvider
                      name="Email"
                      rules="required"
                      v-slot="{ errors, valid }"
                      class="mb-4"
                    >
                      <v-text-field
                        v-model="clienteEmail"
                        label="Email"
                        :error-messages="errors"
                        :success="valid"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                type="submit"
                color="primary"
                text
              >
                {{ (tab === 'tab-pdf') ? 'Descargar' : 'Compartir' }}
              </v-btn>

              <v-btn text @click="closesharePDFDialog">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </ValidationObserver>
    </v-dialog>
  </v-container>
</template>

<script>

import _ from 'lodash'
import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate";
import CtaCteList from '../../components/CtaCteList'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    CtaCteList,
  },

  data: () => ({
    loading: false,
    saving: false,
    loadingClientes: false,
    clientes: [],
    cliente: null,
    clientePrev: {},
    clientesQuery: '',
    menuFecha: false,
    menuFechaVto: false,
    menuFechaEmision: false,
    today: null,
    fecha: null,
    datosTapa: {
      fecha: null,
      idCliente: null,
      razonSocialCliente: null,
      importeTotal: 0,
      comentario: null,
    },
    headers: [
      { text: 'Item', value: 'item', align: 'left', sortable: false },
      { text: 'Medio de Pago', value: 'medioDePago', sortable: false },
      { text: 'Importe', value: 'importe', sortable: false },
      { text: 'Banco', value: 'banco', sortable: false },
      { text: 'Nro', value: 'nroCheque', align: 'right', sortable: false },
      { text: 'Fecha de Emisión', value: 'fechaEmision', align: 'right', sortable: false },
      { text: 'Fecha de Vencimiento', value: 'fechaVto', align: 'right', sortable: false },
      { text: 'Librador', value: 'librador', align: 'right', sortable: false },
      { text: 'Acciones', value: 'action', align: 'right', sortable: false },
    ],
    headersXs: [
      { text: 'Medio de Pago', value: 'medioDePago', sortable: false },
      { text: 'Importe', value: 'importe', sortable: false },
      { text: 'Banco', value: 'banco', sortable: false },
      { text: 'Nro', value: 'nroCheque', align: 'right', sortable: false },
      { text: 'Fecha de Emisión', value: 'fechaEmision', align: 'right', sortable: false },
      { text: 'Fecha de Vencimiento', value: 'fechaVto', align: 'right', sortable: false },
      { text: 'Librador', value: 'librador', align: 'right', sortable: false },
      { text: 'Acciones', value: 'action', align: 'right', sortable: false },
    ],
    items: [],
    itemIndex: -1,
    itemCounter: 0,
    mediosDePago: [],
    bancos: [],
    medioDePagoDetalle: '',
    item: {
      item: null,
      medioDePago: null,
      banco: null,
      importe: 0,
      nroCheque: 0,
      fechaVto: null,
      fechaEmision: null,
      librador: '',
    },
    defaultItem: {
      item: null,
      medioDePago: null,
      banco: null,
      importe: 0,
      nroCheque: 0,
      fechaVto: null,
      fechaEmision: null,
      librador: '',
    },
    itemFormDialog: false,
    commentDialog: false,
    dialogCtaCte: false,
    dialogChangeClient: false,
    sharePDFDialog: false,
    moneyFormatOptions: {
      style: 'currency',
      currency: 'ARS',
    },
    saldoCte:0,
    clienteCtaCte: {},
    fechaVtoMin: null,
    pdfUrl: "",
    tab: 'tab-pdf',
    clienteWhatsApp: '',
    clienteEmail: '',
    noDataText: 'Escriba para buscar...',
  }),

  computed: {
    formTitle: {
      get() {
        return (this.itemIndex > -1) ? 'Editar item' : 'Nuevo item'
      },
    },

    computedFechaFormatted: {
      get: function () {
        if (!this.fecha) return null

        const [year, month, day] = this.fecha.split('-')

        return `${day}/${month}/${year}`
      }
    },

    computedFechaVtoFormatted: {
      get: function () {
        if (!this.item.fechaVto) return null

        const [year, month, day] = this.item.fechaVto.split('-')

        return `${day}/${month}/${year}`
      },
      set: function (newValue) {
        this.item.fechaVto = newValue
      }
    },

    computedFechaEmisionFormatted: {
      get: function () {
        if (!this.item.fechaEmision) return null

        const [year, month, day] = this.item.fechaEmision.split('-')

        return `${day}/${month}/${year}`
      },
      set: function (newValue) {
        this.item.fechaEmision = newValue
      }
    },

    chequeFieldsAreVisible: {
      get: function () {
        const detalle = this.item.medioDePago?.detalle || ''

        return (detalle.toUpperCase().includes("CHEQUES"))
      },
    },
  },

  watch: {
    clientesQuery (val) {
      if (!this.cliente && val) {
        this.noDataText = 'Buscando...'
        this.debounceClientes(val)
      }
    },
  },

  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    },

    debounceClientes: _.debounce(function (val) {
      this.queryClientes(val)
    }, 1000),

    queryClientes (val) {
      this.loadingClientes = true;

      this.$soapClient.call('listarClientes', {
        filtro: {
          consulta: val,
          // pagina_solicitada: 1
          // registrosPorPagina: 100
        },
      })
      .then((response) => {
        this.clientes = response.data.resultado;

        if (!this.clientes.length) {
          this.noDataText = 'No hay datos disponibles'
        }
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.loadingClientes = false;
      })
    },

    save () {
      this.saving = true

      // Actualizar fecha
      this.fecha = new Date().toISOString().substr(0, 10)

      this.datosTapa['_xsi:type'] = 'ns2:TapaCobranza'
      this.datosTapa.fecha = this.fecha

      var items = []
      var itemsAux = {}

      var itemsCount = this.items.length

      for (var i = 0; i < itemsCount; i++) {
        var item = this.items[i]

        itemsAux['item'+(i + 1)] = {
          '_xsi:type': 'ns2:CuerpoCobranza',
          item: item.item,
          idMedioPago: item.medioDePago.id,
          importe: item.importe,
          idBanco: item.banco ? item.banco.id : null,
          nroCheque: item.nroCheque,
          fechaVto: item.fechaVto,
          fechaEmision: item.fechaEmision,
          librador: item.librador,
        }
      }

      items.push(itemsAux)

      this.$soapClient.call('cargarCobranza', {
        datosTapa: this.datosTapa,
        items: items,
      })
      .then(() => {
        this.resetForm()

        this.$eventHub.$emit('snackbar-message', 'Los datos fueron guardados con éxito', 'success')
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, no se pudo completar su solicitud', 'error')
        }
      })
      .then(() => {
        this.saving = false
      })
    },

    saveAndShare () {
      this.saving = true

      //Actualizar fecha
      this.fecha = new Date().toISOString().substr(0, 10)

      this.datosTapa['_xsi:type'] = 'ns2:TapaCobranza'
      this.datosTapa.fecha = this.fecha

      var items = []
      var itemsAux = {}

      var itemsCount = this.items.length

      for (var i = 0; i < itemsCount; i++) {
        var item = this.items[i]

        itemsAux['item'+(i + 1)] = {
          '_xsi:type': 'ns2:CuerpoCobranza',
          item: item.item,
          idMedioPago: item.medioDePago.id,
          importe: item.importe,
          idBanco: item.banco ? item.banco.id : null,
          nroCheque: item.nroCheque,
          fechaVto: item.fechaVto,
          fechaEmision: item.fechaEmision,
          librador: item.librador,
        }
      }

      items.push(itemsAux)

      this.$soapClient.call('cargarCobranzaPdf', {
        datosTapa: this.datosTapa,
        items: items,
      })
      .then((response) => {
        this.pdfUrl = response.data.resultado
        this.clienteWhatsApp = this.cliente.WhatsApp
        this.clienteEmail = this.cliente.Email

        this.sharePDFDialog = true

        this.resetForm()

        this.$eventHub.$emit('snackbar-message', 'Los datos fueron guardados con éxito', 'success')
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, no se pudo completar su solicitud', 'error')
        }
      })
      .then(() => {
        this.saving = false
      })
    },

    setCliente () {
      if (this.cliente && this.cliente?.Id) {
        // Controlar si tiene items cargados
        if (this.items.length) {
          this.dialogChangeClient = true
          return
        }

        this.setClienteData()
      } else {
        this.datosTapa.idCliente = null
        this.datosTapa.razonSocialCliente = null
      }
    },

    setClienteData () {
      this.getSaldo(this.cliente)
      this.datosTapa.idCliente = this.cliente.Id
      this.datosTapa.razonSocialCliente = this.cliente.RazonSocial
      this.clientePrev = {...this.cliente}
    },

    resetForm () {
      this.cliente = null
      this.clientePrev = {}
      this.clientes = []
      this.clientesQuery = ''
      this.datosTapa = {
        fecha: null,
        idCliente: null,
        razonSocialCliente: null,
        importeTotal: 0,
        comentario: null,
      }
      this.items = []
      this.itemIndex = -1
      this.itemCounter = 0

      this.$refs.obs.reset()
    },

    openNewItem () {
      this.itemIndex = -1
      this.item = { ...this.defaultItem }
      this.item.librador = this.datosTapa.razonSocialCliente
      this.itemFormDialog = true
    },

    openEditItem (item) {
      this.itemIndex = this.items.findIndex((elem) => {
        return (item.item === elem.item)
      })
      this.item = item
      this.item.librador = this.datosTapa.razonSocialCliente
      this.itemFormDialog = true
    },

    removeItem (item) {
      var itemIndex = this.items.findIndex((elem) => {
        return (item.item === elem.item)
      })

      this.items.splice(itemIndex, 1)

      this.updateTotals()
      this.closeItemForm()
    },

    resetItemForm () {
      this.item = {...this.defaultItem}
      this.$refs.item.reset()
    },

    closeItemForm () {
      this.resetItemForm()
      this.itemIndex = -1
      this.itemFormDialog = false
    },

    updateTotals () {
      var importeTotal = 0.00

      for (var i = this.items.length - 1; i >= 0; i--) {
        const item = this.items[i]

        importeTotal+= item.importe
      }

      this.datosTapa.importeTotal = importeTotal
    },

    saveItem () {
      if (this.itemIndex > -1) {
        this.editItem()
      } else {
        this.addItem()
      }
    },

    addItem () {
      this.itemCounter+= 1

      this.items.push({
        item: this.itemCounter,
        medioDePago: this.item.medioDePago,
        banco: this.chequeFieldsAreVisible ? this.item.banco : null,
        importe: this.item.importe,
        nroCheque: this.chequeFieldsAreVisible ? this.item.nroCheque : 0,
        fechaVto: this.chequeFieldsAreVisible ? this.item.fechaVto : null,
        fechaEmision: this.chequeFieldsAreVisible ? this.item.fechaEmision : null,
        librador: this.chequeFieldsAreVisible ? this.item.librador : '',
      })

      // TODO: Cerrar o continuar agregando?
      this.updateTotals()
      this.closeItemForm()
    },

    editItem () {
      if (!this.chequeFieldsAreVisible) {
        this.item.banco = null
        this.item.nroCheque = null
        this.item.fechaVto = null
        this.item.fechaEmision = null
        this.item.librador = ''
      }

      this.$set(this.items[this.itemIndex], 'medioDePago', this.item.medioDePago)
      this.$set(this.items[this.itemIndex], 'banco', this.item.banco)
      this.$set(this.items[this.itemIndex], 'importe', this.item.importe)
      this.$set(this.items[this.itemIndex], 'nroCheque', this.item.nroCheque)
      this.$set(this.items[this.itemIndex], 'fechaVto', this.item.fechaVto)
      this.$set(this.items[this.itemIndex], 'fechaEmision', this.item.fechaEmision)
      this.$set(this.items[this.itemIndex], 'librador', this.item.librador)

      this.updateTotals()
      this.closeItemForm()
    },

    addComment () {
      this.commentDialog = true
    },

    closeCommentDialog () {
      this.commentDialog = false
    },

    numberFormat(value, options) {
      const numberFormat = new Intl.NumberFormat('es-AR', options)

      return numberFormat.format(value)
    },

    queryMediosDePago() {
      this.$soapClient.call('listarMediosPago', {
      })
      .then((response) => {
        this.mediosDePago = JSON.parse(response.data.resultado)
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
      })
    },

    queryBancos() {
      this.$soapClient.call('listarBancos', {
      })
      .then((response) => {
        this.bancos = JSON.parse(response.data.resultado)
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
      })
    },

    getSaldo(item) {
      this.$soapClient.call('getCtasCtes', {
        inNroCuenta: item.NroCuenta,
        inCantDias: null,
        inCantMovimientos: 1,
      })
      .then((response) => {
        this.saldoCte = response.data.resultado.saldo
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
      })
    },

    openCtaCte (item) {
      this.clienteCtaCte = item
      this.dialogCtaCte = true
    },

    closeCtaCte () {
      this.dialogCtaCte = false
    },

    changeClientWhithItems(removeItems) {
      this.setClienteData()

      if (removeItems) {
        this.items = []
        this.itemIndex = -1
        this.itemCounter = 0
      }

      this.dialogChangeClient = false
    },

    cancelChangeClientDialog() {
      this.cliente = {...this.clientePrev}
      this.dialogChangeClient = false
    },

    setFechaVtoMin() {
      const fechaVtoMin = new Date(this.item.fechaEmision)

      this.fechaVtoMin = new Date(fechaVtoMin.setDate(fechaVtoMin.getDate() + 1)).toISOString().substr(0, 10)
      this.item.fechaVto = null
    },

    sharePDF() {
      var link = this.pdfUrl

      if (this.tab == "tab-whatsapp") {
        link = `https://wa.me/${this.clienteWhatsApp}?text=${this.pdfUrl}`
      }

      if (this.tab == "tab-email") {
        var subject = "PDF Cobranza";

        link = `mailto:${this.clienteEmail}?subject=${subject}&body=${this.pdfUrl}`
      }

      window.open(link, '_blank');
    },

    closesharePDFDialog() {
      this.sharePDFDialog = false
    },
  },

  mounted() {
    this.today = new Date().toISOString().substr(0, 10)
    this.fechaVtoMin = this.today
    this.fecha = _.cloneDeep(this.today)
    this.queryMediosDePago()
    this.queryBancos()
  }
}

</script>

<style scoped>
  .saldo-adeudado {
    color: darkorange;
  }
</style>
