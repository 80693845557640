var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"align-self-start"},[_c('v-data-table',{staticClass:"elevation-1 align-self-start col-12 pa-0",attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"options":_vm.options,"loading":_vm.loading,"item-key":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Cobranzas")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"primary","disabled":!_vm.items.length,"loading":_vm.loadingExportToExcel,"title":"Exportar a excel","aria-label":"Exportar a exce"},on:{"click":function($event){return _vm.exportToExcel()}}},[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)],1),_c('v-form',{ref:"filterForm"},[_c('v-container',{staticClass:"pt-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"start","justify":"start","no-gutters":_vm.$vuetify.breakpoint.xs}},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"loading":_vm.loadingClientes,"items":_vm.clientes,"search-input":_vm.clientesQuery,"single-line":"","hide-details":"","cache-items":"","clearable":"","label":"Cliente","placeholder":"Buscar cliente","item-text":"RazonSocial","return-object":"","no-data-text":_vm.noDataText},on:{"update:searchInput":function($event){_vm.clientesQuery=$event},"update:search-input":function($event){_vm.clientesQuery=$event},"input":_vm.setCliente},model:{value:(_vm.cliente),callback:function ($$v) {_vm.cliente=$$v},expression:"cliente"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Desde","prepend-icon":"mdi-calendar","single-line":"","hide-details":"","clearable":"","readonly":""},model:{value:(_vm.computedDesdeFormatted),callback:function ($$v) {_vm.computedDesdeFormatted=$$v},expression:"computedDesdeFormatted"}},on))]}}]),model:{value:(_vm.menuDesde),callback:function ($$v) {_vm.menuDesde=$$v},expression:"menuDesde"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menuDesde = false}},model:{value:(_vm.filters.desde),callback:function ($$v) {_vm.$set(_vm.filters, "desde", $$v)},expression:"filters.desde"}})],1)],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Hasta","prepend-icon":"mdi-calendar","single-line":"","hide-details":"","clearable":"","readonly":""},model:{value:(_vm.computedHastaFormatted),callback:function ($$v) {_vm.computedHastaFormatted=$$v},expression:"computedHastaFormatted"}},on))]}}]),model:{value:(_vm.menuHasta),callback:function ($$v) {_vm.menuHasta=$$v},expression:"menuHasta"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menuHasta = false}},model:{value:(_vm.filters.hasta),callback:function ($$v) {_vm.$set(_vm.filters, "hasta", $$v)},expression:"filters.hasta"}})],1)],1)],1)],1)],1)]},proxy:true},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"ml-4",attrs:{"color":"info","aria-label":"Detalle","title":"Detalle"},on:{"click":function($event){return _vm.openDetalle(item)}}},[_vm._v(" mdi-eye-settings ")]),_c('v-btn',{staticClass:"ml-4",attrs:{"icon":"","small":"","color":"primary","loading":_vm.loadingSharePDF,"aria-label":"Compartir PDF","title":"Compartir PDF"},on:{"click":function($event){return _vm.getAndSharePDF(item)}}},[_c('v-icon',[_vm._v("mdi-share-variant")])],1)]}},{key:"item.Importe",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.numberFormat(item.Importe, { style: 'currency', currency: 'ARS' }))+" ")])]}}])}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.sharePDFDialog),callback:function ($$v) {_vm.sharePDFDialog=$$v},expression:"sharePDFDialog"}},[_c('ValidationObserver',{ref:"item",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.sharePDF)}}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" Compartir PDF ")])]),_c('v-divider'),_c('v-tabs',{attrs:{"icons-and-text":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider'),_c('v-tab',{attrs:{"href":"#tab-pdf"}},[_vm._v(" PDF "),_c('v-icon',[_vm._v("mdi-file-pdf-box")])],1),_c('v-tab',{attrs:{"href":"#tab-whatsapp"}},[_vm._v(" WhatsApp "),_c('v-icon',[_vm._v("mdi-whatsapp")])],1),_c('v-tab',{attrs:{"href":"#tab-email"}},[_vm._v(" Email "),_c('v-icon',[_vm._v("mdi-email")])],1)],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"value":"tab-pdf"}}),_c('v-tab-item',{attrs:{"value":"tab-whatsapp"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('ValidationProvider',{staticClass:"mb-4",attrs:{"name":"Celular","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":"Celular","error-messages":errors,"success":valid},model:{value:(_vm.clienteWhatsApp),callback:function ($$v) {_vm.clienteWhatsApp=$$v},expression:"clienteWhatsApp"}})]}}],null,true)})],1)],1)],1),_c('v-tab-item',{attrs:{"value":"tab-email"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('ValidationProvider',{staticClass:"mb-4",attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":"Email","error-messages":errors,"success":valid},model:{value:(_vm.clienteEmail),callback:function ($$v) {_vm.clienteEmail=$$v},expression:"clienteEmail"}})]}}],null,true)})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"type":"submit","color":"primary","text":""}},[_vm._v(" "+_vm._s((_vm.tab === 'tab-pdf') ? 'Descargar' : 'Compartir')+" ")]),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closesharePDFDialog}},[_vm._v("Cerrar")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }