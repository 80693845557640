<template>
  <v-container class="align-self-start">
    <v-data-table
      :headers="headers"
      :items="items"
      :server-items-length="total"
      :options.sync="options"
      :loading="loading"
      class="elevation-1 align-self-start col-12 pa-0"
      item-key="id"
    >
      <template v-slot:top>
        <v-toolbar flat dense>
          <v-toolbar-title>Cobranzas</v-toolbar-title>

          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>

          <v-spacer></v-spacer>

          <v-btn
            icon
            color="primary"
            :disabled="!items.length"
            :loading="loadingExportToExcel"
            @click="exportToExcel()"
            title="Exportar a excel"
            aria-label="Exportar a exce"
          >
            <v-icon>mdi-microsoft-excel</v-icon>
          </v-btn>
        </v-toolbar>

        <v-form ref="filterForm">
          <v-container fluid class="pt-0">
            <v-row
              align="start"
              justify="start"
              :no-gutters="$vuetify.breakpoint.xs"
            >
              <v-col cols="12" md="6" class="pt-0">
                <v-autocomplete
                  v-model="cliente"
                  :loading="loadingClientes"
                  :items="clientes"
                  :search-input.sync="clientesQuery"
                  single-line
                  hide-details
                  cache-items
                  clearable
                  label="Cliente"
                  placeholder="Buscar cliente"
                  item-text="RazonSocial"
                  return-object
                  @input="setCliente"
                  :no-data-text="noDataText"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3" class="pt-0">
                <v-menu
                  v-model="menuDesde"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="computedDesdeFormatted"
                      label="Desde"
                      prepend-icon="mdi-calendar"
                      single-line
                      hide-details
                      clearable
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="filters.desde" @input="menuDesde = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="3" class="pt-0">
                <v-menu
                  v-model="menuHasta"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="computedHastaFormatted"
                      label="Hasta"
                      prepend-icon="mdi-calendar"
                      single-line
                      hide-details
                      clearable
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="filters.hasta" @input="menuHasta = false"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </template>
      <template v-slot:item.action="{ item }">
        <v-icon
          color="info"
          class="ml-4"
          @click="openDetalle(item)"
          aria-label="Detalle"
          title="Detalle"
        >
          mdi-eye-settings
        </v-icon>

        <v-btn
          icon
          small
          color="primary"
          class="ml-4"
          @click="getAndSharePDF(item)"
          :loading="loadingSharePDF"
          aria-label="Compartir PDF"
          title="Compartir PDF"
        >
          <v-icon>mdi-share-variant</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.Importe="{ item }">
        <span class="text-no-wrap">
          {{ numberFormat(item.Importe, { style: 'currency', currency: 'ARS' }) }}
        </span>
      </template>
    </v-data-table>

    <v-dialog v-model="sharePDFDialog" max-width="500px">
      <ValidationObserver ref="item" v-slot="{ passes }">
        <v-form @submit.prevent="passes(sharePDF)">
          <v-card>
            <v-card-title>
              <span class="headline">
                Compartir PDF
              </span>
            </v-card-title>

            <v-divider></v-divider>

            <v-tabs
              v-model="tab"
              icons-and-text
            >
              <v-tabs-slider></v-tabs-slider>

              <v-tab href="#tab-pdf">
                PDF
                <v-icon>mdi-file-pdf-box</v-icon>
              </v-tab>

              <v-tab href="#tab-whatsapp">
                WhatsApp
                <v-icon>mdi-whatsapp</v-icon>
              </v-tab>

              <v-tab href="#tab-email">
                Email
                <v-icon>mdi-email</v-icon>
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item
                value="tab-pdf"
              ></v-tab-item>

              <v-tab-item
                value="tab-whatsapp"
              >
                <v-card flat>
                  <v-card-text>
                    <ValidationProvider
                      name="Celular"
                      rules="required"
                      v-slot="{ errors, valid }"
                      class="mb-4"
                    >
                      <v-text-field
                        v-model="clienteWhatsApp"
                        label="Celular"
                        :error-messages="errors"
                        :success="valid"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item
                value="tab-email"
              >
                <v-card flat>
                  <v-card-text>
                    <ValidationProvider
                      name="Email"
                      rules="required"
                      v-slot="{ errors, valid }"
                      class="mb-4"
                    >
                      <v-text-field
                        v-model="clienteEmail"
                        label="Email"
                        :error-messages="errors"
                        :success="valid"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                type="submit"
                color="primary"
                text
              >
                {{ (tab === 'tab-pdf') ? 'Descargar' : 'Compartir' }}
              </v-btn>

              <v-btn text @click="closesharePDFDialog">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </ValidationObserver>
    </v-dialog>
  </v-container>
</template>

<script>

import _ from 'lodash'
import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate";
import moment from 'moment'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  data: () => ({
    loading: false,
    dialog: false,
    loadingClientes: false,
    loadingSharePDF: false,
    loadingExportToExcel: false,
    clientes: [],
    cliente: null,
    idCliente: -1,
    clientesQuery: '',
    menuDesde: false,
    menuHasta: false,
    filters: {
      desde: new Date().toISOString().substr(0, 10),
      hasta: new Date().toISOString().substr(0, 10),
      pagina_solicitada: 1,
      registrosPorPagina: 10
    },
    search: '',
    headers: [
      { text: 'Fecha', value: 'Fecha', sortable: false },
      { text: 'Cliente', value: 'Razon Social', sortable: false },
      { text: 'Importe Total', value: 'Importe', align:'right', sortable: false },
      { text: 'Acciones', value: 'action', align: 'center', sortable: false },
    ],
    items: [],
    total: 0,
    options: {},
    item: {},
    sharePDFDialog: false,
    pdfUrl: "",
    tab: 'tab-pdf',
    clienteWhatsApp: '',
    clienteEmail: '',
    noDataText: 'Escriba para buscar...',
  }),

  computed: {
    computedDesdeFormatted: {
      get: function () {
        if (!this.filters.desde) return null

        const [year, month, day] = this.filters.desde.split('-')

        return `${day}/${month}/${year}`
      },
      set: function (newValue) {
        this.filters.desde = newValue
      }
    },
    computedHastaFormatted: {
      get: function () {
        if (!this.filters.hasta) return null

        const [year, month, day] = this.filters.hasta.split('-')

        return `${day}/${month}/${year}`
      },
      set: function (newValue) {
        this.filters.hasta = newValue
      }
    },
  },

  watch: {
    options: {
      handler () {
        this.query()
      },
      deep: true,
    },

    clientesQuery (val) {
      if (!this.cliente && val) {
        this.noDataText = 'Buscando...'
        this.debounceClientes(val)
      }
    },

    'idCliente': function () {
      this.refresh()
    },

    'filters.desde': function () {
      this.refresh()
    },

    'filters.hasta': function () {
      this.refresh()
    },
  },

  methods: {
    debounceClientes: _.debounce(function (val) {
      this.queryClientes(val)
    }, 1000),

    queryClientes (val) {
      this.loadingClientes = true;

      this.$soapClient.call('listarClientes', {
        filtro: {
          consulta: val,
        },
      })
      .then((response) => {
        this.clientes = response.data.resultado;

        if (!this.clientes.length) {
          this.noDataText = 'No hay datos disponibles'
        }
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.loadingClientes = false;
      })
    },

    setCliente () {
      if (!this.cliente) {
        this.idCliente = -1
      } else {
        this.idCliente = this.cliente.Id
      }

      const parsedCliente = JSON.stringify(this.cliente);
      sessionStorage.setItem('cobranzasCliente', parsedCliente);
    },

    refresh: function () {
      this.options.page = 1
      this.query()
    },

    query: function () {
      this.loading = true

      this.filters.pagina_solicitada = this.options.page
      this.filters.registrosPorPagina = this.options.itemsPerPage

      this.$soapClient.call('listarCobranzas', {
        idCliente: this.idCliente,
        filtro: this.filters,
      })
      .then((response) => {
        this.items = response.data.resultado
        this.total = this.items.lenght

        const parsedFilters = JSON.stringify(this.filters);
        sessionStorage.setItem('cobranzasFilters', parsedFilters);
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.loading = false
      })
    },

    openDetalle (item) {
      this.$router.push({
        name: 'cobranzas/detalle',
        params: {
          id: item.id,
        }
      })
    },

    numberFormat(value, options) {
      const numberFormat = new Intl.NumberFormat('es-AR', options)

      return numberFormat.format(value)
    },

    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    },

    getAndSharePDF(item) {
      this.loadingSharePDF = true

      this.$soapClient.call('getCobranzaPdf', {
        idTapa: item.id,
      })
      .then((response) => {
        this.pdfUrl = response.data.resultado
        this.clienteWhatsApp = item.WhatsApp
        this.clienteEmail = item.Mail 

        this.sharePDFDialog = true
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.loadingSharePDF = false
      })
    },

    sharePDF() {
      var link = this.pdfUrl

      if (this.tab == "tab-whatsapp") {
        link = `https://wa.me/${this.clienteWhatsApp}?text=${this.pdfUrl}`
      }

      if (this.tab == "tab-email") {
        var subject = "PDF Cobranza";

        link = `mailto:${this.clienteEmail}?subject=${subject}&body=${this.pdfUrl}`
      }

      window.open(link, '_blank');
    },

    closesharePDFDialog() {
      this.sharePDFDialog = false
    },

    exportToExcel() {
      this.loadingExportToExcel = true 

      this.filters.pagina_solicitada = this.options.page
      this.filters.registrosPorPagina = this.options.itemsPerPage
      
      this.$soapClient.call('listarCobranzasExcel', {
        idCliente: this.idCliente,
        filtro: this.filters,
      })
      .then((response) => {
        const ExcelJS = require('exceljs');
        
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Cobranzas');

        // Configurar la orientación de la hoja como horizontal
        worksheet.pageSetup.orientation = 'landscape';

        // Configurar la hoja como hoja A4 por defecto
        worksheet.pageSetup.paperSize = 9;

        // Configurar márgenes
        worksheet.pageSetup.margins = {
            left: 0.5,
            right: 0.5,
        };

        const titleRow = worksheet.addRow(['PLANILLA DE COBRANZAS - ' + sessionStorage.getItem('razonSocial')]);
        worksheet.mergeCells(`A1:J1`);

        titleRow.alignment = {
            horizontal: 'center'
        };

        worksheet.addRow(
          ['FECHA RECIBO', 
           'CLIENTE', 
           'IMPORTE EFECTIVO',
           'IMPORTE CHEQUE',
           'BANCO',
           'NUMERO DE CHEQUE',
           'FECHA EMISION',
           'FECHA COBRO',
           'LIBRADOR',
           'TOTAL RECIBOS',
          ]
        );

        var totalImporteEfectivo = 0
        var totalImporteCheque = 0
        var totalRecibos = 0

        response.data.resultado.forEach(row => {
          var detalleMedioPago = row.detalleMedioPago || ''
          var importeEfectivo = parseFloat(row.importe)
          var importeCheque = ''
          var nroCheque = ''
          var fechaEmisionCheque = ''
          var fechaVtoCheque = ''

          totalImporteEfectivo+= importeEfectivo
          totalRecibos+= row.importe

          if (detalleMedioPago.toUpperCase().includes("CHEQUES")) {
            importeCheque = parseFloat(row.importe)
            nroCheque = row.nroCheque
            importeEfectivo = ''

            totalImporteEfectivo-= importeCheque
            totalImporteCheque+= importeCheque
          }

          if (row.fechaEmisionCheque != '') {
            fechaEmisionCheque = moment(row.fechaEmisionCheque).locale('es').format('DD/MM/YYYY')
          }

          if (row.fechaVtoCheque != '') {
            fechaVtoCheque = moment(row.fechaVtoCheque).locale('es').format('DD/MM/YYYY')
          }

          worksheet.addRow([
            moment(row.fechaEmision, 'DD/MM/YYYY').locale('es').format('DD/MM/YYYY'),
            row.cliente,
            importeEfectivo,
            importeCheque,
            row.detalleBanco,
            nroCheque,
            fechaEmisionCheque,
            fechaVtoCheque,
            row.librador,
            row.importe
          ]);
        });

        worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
          if (rowNumber == 1) {
            row.height = 30;
          } else if (rowNumber == 2) {
            row.height = 58;
          }

          row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
            cell.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };

            cell.alignment = {
              horizontal: 'center',
              vertical: 'middle',  
              wrapText: true       
            };

            if (rowNumber === 1) {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '00b0f0' }
              };

              cell.font = {
                color: { argb: 'ffffff' },
                bold: true,
                underline: true 
              };
            }

            if (rowNumber === 2) {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFFFF' }
              };

              cell.font = {
                bold: true
              };
            }

            if (rowNumber > 2) {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'd9e2f3' }
              };

              if (colNumber === 2) {
                row.height = (cell.text.length + 40)
              }

              if (colNumber === 3 || colNumber === 4 || colNumber === 10) {
                cell.alignment = {
                  horizontal: 'right',
                  vertical: 'middle',  
                  wrapText: true       
                };

                cell.numFmt = '"$"#,##0.00_)'

                cell.font = {
                  color: { argb: '5280c9' },
                  bold: true
                };
              }
            }
          });
        });

        const totalsRow = worksheet.addRow([
          '',
          '',
          totalImporteEfectivo,
          totalImporteCheque,
          '',
          '',
          '',
          '',
          '',
          totalRecibos
        ]);

        totalsRow.height = 30;

        totalsRow.eachCell({ includeEmpty: true }, (cell, colNumber) => {
          cell.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              right: { style: 'thin' },
              bottom: { style: 'thin' }
          };

          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'd9e2f3' }
          };

          if (colNumber === 3 || colNumber === 4 || colNumber === 10) {
            cell.numFmt = '"$"#,##0.00_)'

            cell.font = {
              color: { argb: '000000' },
              bold: true,
            };

            cell.alignment = {
              horizontal: 'right',
              vertical: 'middle',  
              wrapText: true       
            };
          }
        });

        worksheet.columns.forEach((column) => {
            column.width = 15;
        });

        totalsRow.eachCell((cell, colNumber) => {
          if (colNumber === 3 || colNumber === 4 || colNumber === 10) {
            const contentLength = cell.value ? cell.value.toString().length + 2 : 10;

            worksheet.getColumn(colNumber).width = contentLength;
          }
        });

        // Configurar el ancho de las columnas de fecha
        worksheet.getColumn('A').width = 10.44;
        worksheet.getColumn('G').width = 10.44;
        worksheet.getColumn('H').width = 10.44;

        const d = new Date();
        const fileName = `Planilla de cobranzas-${sessionStorage.getItem('razonSocial')}-${d.getDate()}_${d.getMonth() + 1}.xlsx`;

        workbook.xlsx.writeBuffer().then(buffer => {
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();

          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        });
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.loadingExportToExcel = false
      })
    },
  },

  mounted() {
    if (sessionStorage.getItem('cobranzasFilters')) {
      try {
        this.filters = JSON.parse(sessionStorage.getItem('cobranzasFilters'));
      } catch(e) {
        sessionStorage.removeItem('cobranzasFilters');
      }
    }

    if (sessionStorage.getItem('cobranzasCliente')) {
      try {
        this.cliente = JSON.parse(sessionStorage.getItem('cobranzasCliente'));

        if (!this.cliente) {
          this.idCliente = -1
        } else {
          this.idCliente = this.cliente.Id
          this.queryClientes(this.cliente.RazonSocial)
        }
      } catch(e) {
        sessionStorage.removeItem('cobranzasCliente');
      }
    }
  },
};
</script>

<style scoped>
  >>> .v-data-table-header-mobile {
    display: none;
  }
</style>
