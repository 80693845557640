<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="loading"
    disable-pagination
    hide-default-footer
    class="elevation-1 align-self-start col-12 pa-0"
    item-key="id"
  >
    <template v-slot:top>
      <v-card flat>
        <v-card-subtitle class="pb-0">
          Cobranza Nº {{ id }}
        </v-card-subtitle>
        <v-card-title class="py-0">
          {{ tapa['Razon Social'] }}
        </v-card-title>
        <v-card-text>
          Fecha: {{ tapa.Fecha }}
        </v-card-text>
      </v-card>
    </template>
    <template v-slot:item.importe="{ item }">
        {{ numberFormat(item.importe, { style: 'currency', currency: 'ARS' }) }}
    </template>
    <template v-slot:item.fechaVtoCheque="{ item }">
        {{ formatDate(item.fechaVtoCheque) }}
    </template>

    <template v-slot:body.append="{ headers }">
      <tr>
        <th :colspan="headers.length -1" class="text-right">
          Total
        </th>
        <td class="text-right">
          {{ numberFormat(tapa.Importe, { style: 'currency', currency: 'ARS' }) }}
        </td>
      </tr>
    </template>

    <template v-slot:footer>
      <v-card v-if="tapa.Comentario.length > 0" flat>
        <v-card-text>
          <div class="caption">Comentario:</div>
          <div style="white-space: pre-wrap;">{{ tapa.Comentario }}</div>
        </v-card-text>
      </v-card>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          :to="{ name: 'cobranzas' }"
          color="secondary"
          dark
        >
          Volver
        </v-btn>

        <v-spacer></v-spacer>
      </v-card-actions>
    </template>
  </v-data-table>
</template>

<script>

export default {
  data: () => ({
    loading: true,
    id: null,
    tapa: {
      'Razon Social': '',
      Fecha: '',
      Comentario: '',
    },
    headers: [
      { text: 'Medio de Pago', value: 'detalleMedioPago', sortable: false },
      { text: 'Importe', value: 'importe', sortable: false },
      { text: 'Banco', value: 'detalleBanco', sortable: false },
      { text: 'Nro', value: 'nroCheque', align: 'right', sortable: false },
      { text: 'Fecha de Vencimiento', value: 'fechaVtoCheque', align: 'right', sortable: false },
    ],
    items: [],
    item: {},
  }),

  mounted() {
    this.id = this.$route.params.id
    this.query()
  },

  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    },

    query: function () {
      this.loading = true

      this.$soapClient.call('listarCobranzaCuerpo', {
        idTapa: this.id,
      })
      .then((response) => {
        this.tapa = response.data.resultado.tapa
        this.items = response.data.resultado.items
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.loading = false
      })
    },

    numberFormat(value, options) {
      const numberFormat = new Intl.NumberFormat('es-AR', options)

      return numberFormat.format(value)
    },
  }
}

</script>

<style>
  >>> .v-data-table-header-mobile {
    display: none;
  }
</style>
